<template>
  <div style="margin-top: 20px;padding-left: 20px;padding-right: 20px;margin-bottom: 30px;">
    <el-page-header @back="goBack">
      <template #content>
        <div style="display: flex;">
          <p style="margin-right: 10px;">三方供应商名称：{{ data.supplierInfo.supplierName }}</p>
        </div>
      </template>
    </el-page-header>
  </div>
  <el-form :inline="true" :model="data" style="margin-left: 20px;">
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.regionCodeList"
        :options="areaOptions"
        clearable
        filterable
        @change="changeArea()"
        placeholder="请选择门店区域"
        :show-all-levels="false"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCodeList"
        :options="data.shopList"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="queryShops">查询</el-button>
      <el-button @click="resetQuery">重置</el-button>
    </el-form-item>
    <el-form-item>
      <el-button @click="addShop">编辑供货门店</el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" width="100" />
    <el-table-column prop="storeCode" label="门店编号" align="center" />
    <el-table-column prop="storeName" label="门店名称" align="center" />
    <el-table-column prop="storeShortName" label="门店简介" align="center" />
    <el-table-column prop="characterTypeName" label="门店类型" align="center"> </el-table-column>
    <el-table-column prop="storeAreaName" label="门店区域" align="center"> </el-table-column>
    <el-table-column label="门店地址" align="center">
      <template #default="scope">
        <span
          style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
          :title="scope.row.address"
        >
          {{ scope.row.address }}</span
        >
      </template>
    </el-table-column>

    <el-table-column label="操作" width="180" align="center">
      <template #default="scope">
        <el-button link type="text" size="small" @click="showDetail(scope.row)">停止供货</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total,prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <!-- 批量下发 -->
  <BatchDelivery
    v-if="data.dialogVisible"
    :propData="data.supplierInfo.code"
    :dialogVisible="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
    :isNew="true"
  ></BatchDelivery>
</template>

<script setup>
import commonApi from '@/api'
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, ref, nextTick } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import BatchDelivery from '../../component/BatchDelivery.vue'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        data[i].children = undefined
      } else {
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

const data = reactive({
  storeCodeList: [],
  shopList: [],
  regionCodeList: [],
  areaCode: [],
  supplierInfo: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  dialogVisible: false,
  accountMsg: {},
  productTypes: [],
  articleTypes: [],
})

const router = useRouter()

onMounted(() => {
  data.supplierInfo = JSON.parse(sessionStorage.getItem('purchase-info'))
  getAreas()
  queryShops()
  getShops()
})

const getShops = () => {
  let params = {}
  if (data.areaCode.length > 0) {
    params.withInOrgans = data.areaCode
  }
  commonApi.getNewShop(params).then(res => {
    if (res.code === '200') {
      data.shopList = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const goBack = () => {
  router.back()
}
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const resetQuery = () => {
  data.regionCodeList = []
  data.areaCode = []
  data.storeCodeList = []
  data.currentPage = 1
  data.pageSize = 10
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchData()
}

// 获取门店列表数据
const searchData = () => {
  let query = {
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
    supplierCode: data.supplierInfo.code,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  API.queryThirdPartySupplierStoreAll(query).then(res => {
    if (res?.code == 200) {
      data.tableData = res.data?.records || []
      data.total = res.data.total
    }
  })
}
const queryShops = () => {
  data.currentPage = 1
  searchData()
}

//添加
const addShop = () => {
  data.dialogVisible = true
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchData()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchData()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchData()
}

const showDetail = row => {
  API.updateIsSupper({
    storeCode: row.storeCode,
    supplierCode: data.supplierInfo.code,
  }).then(res => {
    if (res?.code == 200) {
      ElMessage.success('操作成功')
      searchData()
    }
  })
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    span {
      width: 100px;
      margin-left: 20px;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
